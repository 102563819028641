.RatingBar {
  display: flex;
  flex-direction: row;
}

.RatingDot {
  background-color: var(--light-gray);
  height: 10px;
  width: 10px;
  border-radius: 5px;
  margin: 2.5px;

  @media (max-width: 1024px) {
    height: 7.5px;
    width: 7.5px;
    margin: 1.75px;
  }
}

.RatingDotFilled {
  background-color: #FFAFAF;
}