.DisclaimerBackground {
  background-color: white;
  padding: 0px 17.6px;
}

.DisclaimerContainer {
  max-width: 50%;

  @media (max-width: 1440px) {
    max-width: 50%;
  }

  @media (max-width: 1024px) {
    max-width: 70%;
  }

  @media (max-width: 768px) {
    max-width: 90%;
  }

  @media (max-width: 600px) {
    max-width: 100%;
  }
}

.DisclaimerHeading {
  margin-bottom: 24px;
}

.DisclaimerText {
  @extend .DisclaimerHeading;
  font-weight: 400;
}

.DisclaimerTextButton {
  margin-top: 20px;

  @media (max-width: 768px) {
    margin-top: 18px;
  }
}