.TreatmentIntro {
  padding: 32px;
  max-width: 70%;

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 0px 16px;
  }
}

.TreatmentIntroHeading {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 25.6px;
  color: var(--gray-2);
  margin-bottom: 25.6px;

  @media (max-width: 768px) {
    font-size: 12.8px;
    line-height: 17.3px;
    margin-bottom: 17.6px;
  }
}

.TreatmentIntroDescription {
  font-style: normal;
  font-weight: 350;
  font-size: 19px;
  line-height: 25.6px;
  color: var(--gray-2);

  br {
    margin: 6.5px 0;
    display: block;
    content: "";
    clear: both;
    overflow: hidden;
    height: 0px;
  }

  @media (max-width: 768px) {
    font-size: 12.8px;
    line-height: 17.3px;
    font-weight: 400;

    br {
      margin: 3px 0;
    }
  }
}