.Welcome-Screen-Content {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
}

.Welcome-Screen-Bottom-Overlay {
  content: url("../../assets/images/welcome-background-1440.png");
  position: absolute;
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  bottom: var(--footer);

  @media (max-width: 1024px) {
    height: 45%;
  }

  @media (max-width: 425px) {
    height: 45%;
  }
}

.Welcome-Circle-Top-Overlay {
  position: absolute;
  top: 15%;
  height: 8%;
  right: 20%;
  content: url("../../assets/images/filled-circle.png");

  @media (max-width: 1024px) {
    height: 6%;
    right: 17%;
  }

  @media (max-width: 768px) {
    height: 4%;
    right: 15%;
  }
}

.Welcome-Circle-Bottom-Overlay {
  position: absolute;
  height: 15%;
  left: 15%;
  bottom: 42%;
  content: url("../../assets/images/big-circle.png");

  @media (max-width: 1024px) {
    height: 12%;
    left: 13%;
  }

  @media (max-width: 768px) {
    height: 8%;
    left: 12%;
    bottom: 50%;
  }
}

.Welcome-Description {
  text-align: center;
  padding: 24px 10px;
}