.ToggleButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5px;
  padding-top: 38px;

  @media (max-width: 768px) {
    padding-top: 12px;
  }
}

.ToggleButton {
  padding: 8px 24px;
  background-color: white;
  transition: background-color 0.5s ease;
  border: 1px solid rgba(46, 46, 47, 0.5);
  border-radius: 5px;
  margin: 0px 8px;
}

.ToggleButtonSelected {
  background-color: var(--blue);
}

.ToggleButtonTextColor {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -1.33333px;
  color: var(--gray-2);
  transition: color 0.5s ease;
  text-transform: uppercase;
  
  @media (max-width: 768px) {
    font-weight: 600;
    font-size: 12.8px;
    line-height: 18px;
  }
}

.ToggleButtonTextColorSelected {
  color: white;
}

.TextFieldErrorText {
  color: red;
  font-family: 'Satoshi';
  font-style: normal;
  text-align: center;
}