.ImageCarousalContainer {
  width: 100%;
  overflow: auto;
}

.ImageCarousal {
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    width: unset;
  }
}

.ImageButton {
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  cursor: pointer;

  @media (max-width: 768px) {
    border-radius: 10px;
    margin: 14px;
    margin-bottom: 0px;
  }
}

.ImageButton-Image {
  border-radius: 15px;
  background-color: var(--light-gray-3);
  width: 320px;
  aspect-ratio: 1;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 200px;
  }
}

.ImageButton-Label {
  background-color: white;
  padding: 6.4px 11.2px;
  position: relative;
  bottom: 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 3px;

  @media (max-width: 768px) {
    padding: 4px 8px;
  }
}

.ImageButtonSelected {
  background-color: var(--blue);
  transition: background-color 0.5s ease;
}

.ImageButtonText {
  font-weight: 400;
  transition: color 0.5s ease;
}

.ImageButtonTextSelected {
  color: white;
  transition: color 0.5s ease;
}

.TextColor {
  color: white;
}