.NavBar {
  width: var(--taskbar-width);
  background-color: var(--light-gray-5);
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    position: absolute;
    width: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    height: 100%;
    z-index: 2;
    transition: 0.3s width ease;
    overflow: hidden;
  }
}

.NavBarOpen {
  @media (max-width: 1024px) {
    width: var(--taskbar-width);
  }
}

.NavBarOverlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  background-color: var(--gray-1);
  opacity: 0.5;
  z-index: 2;

  @media (min-width: 1024px) {
    display: none;
  }
}

.NavBarHeader {
  padding-top: 35px;
  padding-left: 50px;
  padding-bottom: 12px;

  @media (max-width: 768px) {
    padding-top: 20px;
    padding-left: 30px;
    padding-bottom: 10px;
  }
}

.NavBarHeaderText {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.22375px;
  text-transform: capitalize;
  color: rgba(46, 46, 47, 0.6);

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
}

.NavBarUnderline {
  border: 0.5px solid #7a7b7c;
  width: calc(100% - 50px);
  margin-left: 50px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    width: calc(100% - 30px);
    margin-left: 30px;
    margin-bottom: 10px;
  }
}

.NavBarButtonContainer {
  padding: 10px;
  padding-left: 0px;
  margin-left: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.4;
  transition: 0.3s scale ease;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-left: 30px;
    padding: 10px;
    padding-left: 0px;
  }
}

.NavBarButtonContainer:hover {
  scale: 1.05;
}

.NavBarButtonContainerSelected {
  opacity: 1;
}

.NavBarButtonImage {
  width: 24px;
  height: 24px;
  margin: unset;
  margin-right: 12px;

  @media (max-width: 768px) {
    width: 18px;
    height: 18px;
    margin: unset;
    margin-right: 8px;
  }
}

.NavBarButtonText {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 32px;
  letter-spacing: 0.22375px;
  text-transform: capitalize;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
}


