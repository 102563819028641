.QuestionContent {
  display: flex;
  flex-direction: row;
  padding: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0px;
  }
}

.QuestionImage {
  width: 288px;
  aspect-ratio: 0.75;
  background-color: var(--light-gray-3);
  object-fit: cover;

  @media (max-width: 768px) {
    width: 204.8px;
  }
}

.QuestionRightSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  margin-left: 40px;
  align-self: center;

  @media (max-width: 768px) {
    margin-left: 0px;
    margin-top: 42px;
    max-width: min-content;
  }
}

.QuestionText {
  text-align: center;
  font-weight: 100;
  font-size: 19.2px;
  line-height: 25.6px;

  @media (max-width: 768px) {
    font-size: 12.8px;
    line-height: 17.6px;
  }
}

.QuestionButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 45px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-top: 19.2px;
  }
}

.QuestionButton {
  padding: 23.2px 45px;
  background-color: var(--white);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 5px;
  margin: 0px 12px;

  @media (max-width: 768px) {
    padding: 15px 40px;
    margin: 0px 8px;
  }
}

.QuestionButtonSelected {
  background-color: var(--blue);
  transition: background-color 0.5s ease;
}

.QuestionButtonText {
  transition: color 0.5s ease;
  font-weight: 400;
  line-height: 20.8px;
  font-size: 16px;
}

.QuestionButtonTextSelected {
  color: white;
  transition: color 0.5s ease;
}