@tailwind base;
@tailwind components;
@tailwind utilities;

@function scale($value) {
  @return calc(#{$value} * 0.8);
}

:root {

  // dimensions

  --taskbar-width: #{scale(316px)};
  --header: #{scale(100px)};
  --footer: #{scale(100px)};

  @media (max-width: 768px) {
    --taskbar-width: #{scale(275px)};
    --header: #{scale(62.5px)};
    --footer: #{scale(67.5px)};
  }

  // colors
  --home-cyan: #BAE1E3;
  --light-cyan: #EFFEFF;
  --light-cyan-2: #F4FFFF;
  --blue: #3B00D1;
  --navy-blue: #496DB3;
  --blue-purple: #AB8AFF;
  --white: white;
  --black: black;
  --gray-1: #2E2F2E;
  --gray-2: #2E2E2F;
  --gray-3: #4C4C4C;
  --gray-4: #818181;
  --green: #01B429;
  --blue-gray: #EFF3FF;
  --light-gray: #D9D9D9;
  --light-gray-2: #DDDDDD;
  --light-gray-3: #EFEFEF;
  --light-gray-4: #EFFEFF;
  --light-gray-5: #ECEFEF;
  --medium-gray: #B7B7B7;
  --light-green: #EEFFEE;
  --creme-green: #CFFFCE;
  --light-yellow: #FFF4D9;

}

@media (min-width: 1024px) {

  /* width */
  ::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--gray-4);
    border-radius: 10px;
    background-clip: content-box;
    border: 4px solid transparent;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--gray-4);
  }
}