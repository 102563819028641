.ReturnButton {
  display: flex;
  flex-direction: row;
  border: none;
  background-color: transparent;
  align-items: center;
}

.ReturnButtonImage {
  content: url("../../assets/images/return.png");
  width: 24px;
  margin-right: 5px;

  @media (max-width: 768px) {
    width: 21px;
    margin-right: 5px;
  }
}

.ReturnButtonTextColor {
  color: black;
  font-size: 16px;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}