.DescriptionCardMainContainer {
  padding: 10px 40px;
  padding-right: 20px;

  @media (max-width: 1024px) {
    padding: unset;
    padding-top: 50px;
    width: 60%;
    margin: auto;
    height: calc(100vh - var(--footer));
    overflow: auto;
  }
  
  @media (max-width: 768px) {
    width: 70%;
    margin: auto;
    height: calc(100% - var(--footer));
  }

  @media (max-width: 600px) {
    width: 90%;
  }

  @media (max-width: 425px) {
    width: 100%;
  }

}

.DescriptionCard {
  display: flex;
  flex-direction: column;
  position: inherit;
  background-color: white;
  box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.25);
  border-radius: 15px 0px;
  max-width: 550px;
  padding: 28px;
  transition: margin 0.5s ease, max-height 0.5s ease;

  @media (max-width: 1024px) {
    box-shadow: 2px 8px 15px rgba(0, 0, 0, 0.2);
    max-width: unset;
    transition: max-height 0.5s ease;
  }
  
  @media (max-width: 768px) {
    margin: 13px 20px;
    max-height: calc(100% - var(--footer));
  }
}

.DescriptionCardExpanded {
  @media (max-width: 1024px) {
    max-height: unset;
  }
}

.DescriptionCardHeader {
  display: flex;
  flex-direction: row;
  padding-bottom: 6px;

  @media (max-width: 768px) {
    padding-bottom: 12px;
  }
}

.DescriptionCardIndex {
  position: relative;
  top: 0px;
  left: 0px;
  margin: 2px;
  width: min-content;
  padding: 5.6px 12px;
  width: 37px;
  text-align: center;
  border: 1px solid var(--gray-2);
  border-radius: 8px 0px;
  font-weight: 700;
  transition: background-color 0.5s ease;
  align-self: flex-start;

  @media (max-width: 768px) {
    margin: 2px;
    padding: 6px 18px;
    width: 48px;
  }
}

.DescriptionIndexText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 12.8px;
  line-height: 16px;
  letter-spacing: -0.170123px;
  color: var(--gray-1);

  @media (max-width: 768px) {
    letter-spacing: -0.144444px;
  }
}

.DescriptionTitleText {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 700;
  font-size:  32px;
  line-height: 28px;
  align-items: center;
  color: var(--gray-2);
  padding: 0px 20px;
  text-align: left;
  align-self: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 22px;
  }
}

.DescriptionDetails {
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}

.DescriptionDetailsExpanded {
  @media (max-width: 1024px) {
    overflow: unset;
  }
}

.DescriptionCardSectionHeading {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 34.4px;
  display: flex;
  align-items: center;
  letter-spacing: -0.33px;
  color: rgba(46, 46, 47, 0.8);
  padding-top: 20px;

  @media (max-width: 768px) {
    font-size: 18px;
    padding-top: 11.2px;
  }
}

.DescriptionCardSectorDescription {
  padding-top: 10px;
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32.4px;
  letter-spacing: -0.33px;

  @media (max-width: 768px) {
    padding-top: 4.8px;
    font-size: 18px;
    line-height: 24.3px;
  }
}

.DescriptionCardFooterContainer {
  display: flex;
  flex-direction: row;
  margin-top: 22.4px;

  @media (max-width: 768px) {
    display: none;
  }
}

.RecoveryTimeTitle {
  @extend .DescriptionCardSectionHeading;
  align-self: flex-end;
  width: 35%;
  margin-bottom: 6px;
}

.DescriptionCardFooterRightContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 28.8px;
}

.DescriptionCardFooterRightTopText {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 700;
  font-size: 11.2px;
  line-height: 15.2px;
  display: flex;
  align-items: center;
  letter-spacing: -0.333333px;
  color: var(--black);
  margin-bottom: 7px;
}

.DescriptionCardFooterRightBottomText {
  padding: 16px 20.8px;
  background: var(--light-green);
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 12.8px;
  line-height: 17.6px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.333333px;

  color: var(--black);
}

.DescriptionCardButton {
  margin-top: 35.2px;
  align-self: center;
  border-radius: 40px;
  padding: 12.8px 25.6px;

  @media (max-width: 768px) {
    border-radius: 8px;
    margin-top: 19.2px;
    padding: 8px 16px;
  }
}

.DescriptionCardButtonText {
  font-size: 14.4px;
  line-height: 19.2px;

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 16px;
  }
}

.DescriptionShowMore {
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin-top: 11.2px;
  }

}

.DescriptionShowMoreButton {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ShowMoreText {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.333333px;

  color: rgba(0, 0, 0, 0.6);
}

.ShowMoreImage {
  content: url("../../assets/images/show-more-arrow.png");
  margin-left: 4px;
  width: 20px;
}

.ShowMoreImageExpanded {
  transform: rotate(180deg);
}

.HorizontalLine {
  margin-top: 7px;
  height: 1px;
  background-color: var(--black);
  width: 100%;
}