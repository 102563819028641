.Height {
  height: 100%;
}

.Treatment-Container {
  @extend .Height;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.Treatment-Screen-Bottom-Overlay {
  content: url("../../assets/images/treatment-overlay-1440.png");
  position: absolute;
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30%;
  bottom: var(--footer)
}

.Treatment-Overlay-Section {
  position: inherit;
  width: 100%;
  height: inherit;
  transition: 0.3s visibility ease, 0.3s opacity ease;
  overflow: hidden;
}

.Treatment-Overlay-Section-Hide {
  opacity: 0;
  visibility: hidden;
}

.Treatment-Circle-Container-Bottom-Overlay {
  position: absolute;
  width: 100%;
  height: 30%;
  bottom: var(--footer);
}

.Treatment-Circle-Bottom-Overlay {
  position: absolute;
  top: -14%;
  right: 5%;
  height: 28%;
  content: url("../../assets/images/circle-red-1440.png");

  @media (max-width: 1024px) {
    top: -32%;
  }

  @media (max-width: 768px) {
    top: 7%;
    height: 12%;
    right: 6%;
  }

  @media (max-width: 425px) {
    top: 2%;
    right: 4%;
  }
}

.Treatment-Right-Section {
  flex: 1;
  width: 100%;
}

.Treatment-Header {
  height: 80px;
  background: linear-gradient(90.01deg, var(--light-yellow) 49.45%, rgba(234, 234, 234, 0) 91.29%);
  padding-left: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    height: 62px;
    padding-left: 16px;
  }

}

.Treatment-Header-Breadcrumb {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--gray-2);

  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 12px;
  }
}

.Treatment-Header-Title {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--gray-2);

  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 18px;
  }
}

.Treatment-Right-Section-NavBar-Border {
  border: 1px solid var(--gray-3);
  margin: 0px 32px;

  @media (max-width: 768px) {
    border: 0.5px solid var(--gray-3);
    margin: 0px 46px;
  }
}

.Treatment-Content {
  height: calc(100% - var(--header) - var(--header) - 2px);
  overflow: auto;

  @media (max-width: 768px) {
    height: calc(100% - var(--footer) - var(--header) - 36.5px);
    padding-top: 25.6px;
  }
}

.Menu-Button {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 15px;

  @media (min-width: 1024px) {
    display: none;
  }

  @media (min-width: 768px) {
    height: 40px;
    width: 40px;
  }
}

.Menu-Icon {
  width: 16px;
  @media (min-width: 768px) {
    width: 20px;
  }
}