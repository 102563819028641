.TreatmentExerciseCardContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  margin-right: 48px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.TreatmentExerciseCardMain {
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.TreatmentExerciseCard {
  background: var(--light-cyan-2);
  border: 1px solid rgba(46, 46, 47, 0.5);
  border-radius: 22px;
  padding: 20px;
  padding-bottom: 0px;
  max-width: 430px;
  display: flex;
  flex-direction: column;
  aspect-ratio: 10 / 8;

  @media (max-width: 768px) {
    border: 2px solid rgba(46, 46, 47, 0.5);
    border-radius: 16px;
    padding: 12px;
    padding-bottom: 0px;
    max-width: 390px;
    min-width: 250px;
  }
}

.TreatmentExerciseCardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.TreatmentExerciseOutsideTitle {
  padding-right: 32px;
  padding-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 11.2px;
  color: var(--gray-2);

  @media (max-width: 768px) {
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 12px;
    font-size: 14px;
  }
}

.TreatmentThumbnail {
  width: 430px;
  max-height: 300px;
  object-fit: contain;
  @media (max-width: 768px) {
    width: 250px;
    max-height: 150px;
  }
}

.TreatmentExerciseCardInfo {
  padding-right: 10px;
}

.TreatmentExerciseCardLabel {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.439164px;
  color: var(--gray-2);
  margin-bottom: 2px;

  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 16px;
  }
}

.TreatmentExerciseCardDifficulty {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.TreatmentExerciseCardDifficultyText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.439164px;
  color: var(--gray-2);
  margin-right: 6px;

  @media (max-width: 768px) {
    font-size: 11px;
    line-height: 14px;
  }
}

.TreatmentExerciseCardDurationText {
  @extend .TreatmentExerciseCardDifficultyText
}

.TreatmentExerciseCardLevel {
  border: 1.5px var(--black) solid;
  padding: 7px 12px;
  border-radius: 12px;
  text-wrap: nowrap;

  @media (max-width: 768px) {
    border: 1px var(--black) solid;
    border-radius: 9px;
  }
}

.TreatmentExerciseCardLevelText {
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 13px;
  }
}

.TreatmentExerciseCardVideoBlueprint {
  background-color: var(--white);
  margin-top: 18px;
  border-radius: 24px 24px 0px 0px;
  flex: 1;
  object-fit: cover;

  @media (max-width: 768px) {
    border-radius: 16px 16px 0px 0px;
  }
}

.TreatmentExerciseCardHover {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  cursor: pointer;
  transition: 0.3s opacity ease;
  max-width: 430px;

  @media (max-width: 768px) {
    max-width: 390px;
  }
}

.TreatmentExerciseCardHover:hover {
  opacity: 1;
}

.TreatmentExerciseCardHoverCircle {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  padding: 20px;
  background: rgba(237, 249, 250, 0.8);
  border: 2px solid #3B00D1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TreatmentExerciseCardHoverCircleText {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.720741px;
}