.ImageCarousalPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  width: 100%;

  @media (max-width: 768px) {
    max-height: inherit;
  }
}