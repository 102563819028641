.ButtonLogin {
    padding: 12.8px 25.6px;
    border-radius: 12px;
    background: var(--blue);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  
    transition: all ease 0.5s;
    cursor: pointer;
    border: none;
    width: 100%;
    background-size: 300%;
    background-position: 0 0;
    color: #000;
    text-decoration: none;
    background-position: 90% 0%;
    background-image: linear-gradient(to right, var(--blue), var(--blue-purple), var(--blue));
    margin-bottom: 20px;
    
    @media (max-width: 768px) {
      padding: 8px 20px;
      background-position: unset;
      background-image: unset;
    }
  }
  
  .ButtonLogin:hover {
    background-position: 0 -10%;
  }
  
  .ButtonTextLogin {
    font-style: regular;
    font-size: 16px;
    line-height: calc(32.4px * 0.8);
    align-items: start;
    vertical-align: top;
    color: white;
    font-weight: 500 !important;
  }
/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #25b09b;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}