.loading {
  align-items: center;
  display: flex;
  justify-content: center;
}

.loading__dot {
  animation: dot ease-in-out 2s infinite;
  background-color: var(--white);
  border: 1px solid var(--gray-2);
  border-radius: 10px;
  height: 19.68px;
  width: 19.68px;
  margin: 0px 10.75px;

  @media (max-width: 768px) {
    height: 9.6px;
    width: 9.6px;
    margin: 0px 5.2px;
  }
}

.loading__dot:nth-of-type(2) {
  animation-delay: 0.4s;
}

.loading__dot:nth-of-type(3) {
  animation-delay: 0.6s;
}

@keyframes dot {
  0% {
    background-color: var(--white);
  }

  50% {
    background-color: var(--blue);
  }

  100% {
    background-color: var(--white);
  }
}