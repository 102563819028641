.FontColor {
  color: white;
  font-size: 24px;
}

.FooterLogo {
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    align-items: center;
  }
}

.FooterLogoLine1 {
  display: flex;
  flex-direction: row;
  align-items: center;

  .SunndioHealth-Year {
    @extend .FontColor;
    font-size: 16px;
    line-height: 18.9px;
    font-weight: 100;

    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 14px;
    }
  }

  .Copyright {
    @extend .FontColor;
    font-size: 22px;
    line-height: 24.3px;
    font-weight: 400;
    margin-left: 5px;
    margin-right: 5px;
    @media (max-width: 768px) {
      font-size: 18px;
      margin-left: 3px;
      margin-right: 3px;
      line-height: 20px;
    }
  }
}

.FooterLogoLine2 {
  @extend .FontColor;
  font-weight: 100;
  font-size: 12px;
  line-height: 10px;
  @media (max-width: 768px) {
    font-size: 9px;
    line-height: 14px;
  }
}