.LoadingStudio {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.LoadingStudioText {
  font-weight: 100;
  font-size: 20px;
  line-height: 25.6px;
  letter-spacing: -0.33%;
  margin-bottom: 19.4px;

  @media (max-width: 768px) {
    font-size: 12.8px;
    line-height: 17.6px;
    margin-bottom: 19.2px;
  }
}

.LoadingStudioLoader {
  padding-top: 40px;
  @media (max-width: 768px) {
    padding-top: 9.6px;
  }
}