.ProceedButton {
  display: flex;
  flex-direction: row;
  border: none;
  background-color: transparent;
  align-items: center;
}

.ProceedButtonImage {
  content: url("../../assets/images/return.png");
  transform: rotate(180deg);
  width: 24px;
  margin-left: 5px;

  @media (max-width: 768px) {
    width: 21px;
  }
}

.ProceedButtonTextColor {
  color: black;
  font-size: 16px;
  font-weight: 400;
}