.HorizontalNavBar {
  padding: 23px 32px;
  display: flex;
  overflow: auto;

  @media (max-width: 768px) {
    padding: 24px 16px;
  }
}

.HorizontalNavBarButtonContainer {
  padding: 8px 24px;
  background-color: var(--gray-4);
  border-radius: 8px;
  margin-right: 14px;
  cursor: pointer;
  transition: 0.5s background-color ease, 0.5s opacity ease;

  @media (max-width: 768px) {
    padding: 6.8px 16px;
    margin-right: 11.2px;
  }
}

.HorizontalNavBarButtonContainerSelected {
  background-color: var(--blue);
}

.HorizontalNavBarButtonContainerPrevious {
  background-color: var(--blue);
  opacity: 0.38;
}

.HorizontalNavBarButtonText {
  color: var(--white);
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.58997px;
  text-wrap: nowrap;

  @media (max-width: 768px) {
    font-size: 11.2px;
    line-height: 15.2px;
  }
}