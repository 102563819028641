.QuestionMainContainer {
  height: calc(100% - var(--footer) - var(--header));
}

.QuestionMainScreenContainer {
  overflow-y: auto;

}

.QuestionScreenContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: 100%;
}

.Flex1 {
  flex: 1;
}

.ProgressBarMargin {
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.QuestionHeadingText {
  padding-bottom: 20px;
  padding-top: 20px;

  @media (max-width: 768px) {
    padding-bottom: 10px;
  }
}

.QuestionReturnButtonContainer {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1;
  width: 100%;
}

.QuestionReturnButton {
  // position: absolute;
  margin-top: 10px;
  margin-bottom: 30px;
  margin-left: 25px;

  @media (max-width: 768px) {
    margin-top: 8px;
    bottom: 87px;
    left: 20px;
  }
}
