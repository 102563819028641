@import '../../App.scss';

.FooterTopBar {
  width: 100%;
  background: linear-gradient(90deg, rgba(255, 244, 217, 0) 0%, var(--light-yellow) 50.71%);
  display: flex;
  justify-content: center;
  height: scale(112.5px);
  align-items: center;

  @media (max-width: 768px) {
    height: scale(92.5px);
  }
  @media (min-width:769px) and (max-width:1024px) {
    height: scale(92.5px) !important;
  }
  @media (min-width:1025px) and (max-width:1280px) {
    height: scale(92.5px) !important;
  }
}

.FooterTopBarText {
  font-weight: 200;
}