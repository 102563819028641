.ProgressCard {
  position: inherit;
  background-color: white;
  box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.25);
  border-radius: 15px 0px;
  max-width: 344px;
  margin: 10px 40px;
  margin-left: 20px;
  width: -webkit-fill-available;
  width: -moz-available;
  transition: margin 0.5s ease;

  @media (max-width: 1024px) {
    box-shadow: 2px 8px 15px rgba(0, 0, 0, 0.2);
    margin: 13px 20px;
  }

  @media (max-width: 768px) {
    max-width: 283px;
  }
}

.ProgressCard:hover {
  cursor: pointer;
}

.ProgressCardSelected {
  margin: 10px 20px;
  transition: margin 0.5s ease;

  @media (max-width: 1024px) {
    margin: 13px 20px;
  }
}

.ProgressCardIndex {
  position: relative;
  top: 0px;
  left: 0px;
  margin: 2px;
  width: min-content;
  border-radius: 15px 0px;
  padding: 6.6px 8px;
  width: 40px;
  text-align: center;
  background-color: var(--light-gray);
  font-weight: 700;
  transition: background-color 0.5s ease;

  @media (max-width: 768px) {
    margin: 2px;
    padding: 6px 16.5px;
    width: 45px;
  }
}

.ProgressCardIndexSelected {
  transition: background-color 0.5s ease;
}

.FirstIndexColor {
  background-color: var(--creme-green);
}

.SecondIndexColor {
  background-color: var(--light-gray);
}

.ThirdIndexColor {
  background-color: var(--medium-gray);
}

.IndexText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 15.2px;
  line-height: 18.4px;
  letter-spacing: -0.170123px;
  color: var(--gray-1);

  @media (max-width: 768px) {
    font-size: 12.8px;
    line-height: 16px;
    letter-spacing: -0.144444px;
  }
}

.ProgressBarContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 10.6px;
  align-items: center;

  @media (max-width: 768px) {
    margin-top: 16.8px;
  }
}

.GrayedProgressBar {
  height: 11.2px;
  width: max-content;
  background: var(--light-gray-2);
  border-radius: 0px 10px 10px 0px;
  margin-right: 18px;
  margin-left: 25px;
  flex: 1;

  @media (max-width: 768px) {
    margin-right: 15.5px;
    margin-left: 12.8px;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}

.ColoredProgressBar {
  animation: 1s ease-out 0s 1 slideInFromLeft;
  height: 11.2px;
  background: linear-gradient(90deg, var(--blue-gray) 0%, var(--navy-blue) 57.42%, var(--green) 99.68%);
  border-radius: 0px 10px 10px 0px;
  transition: width 0.5s ease;
  transform-origin: left;
}

.ProgressBarText {
  margin-right: 25px;
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21.6px;
  letter-spacing: -0.333333px;

  @media (max-width: 768px) {
    margin-right: 20px;
    font-size: 12.8px;
    font-weight: 400;
    line-height: 17.6px;
  }
}

.PossibleDiagnosticText {
  margin-top: 9.6px;
  margin-left: 22.4px;
  margin-right: 22.4px;
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
  align-items: center;
  color: var(--gray-2);

  @media (max-width: 768px) {
    margin-left: 12.8px;
    margin-right: 12.8px;
    margin-top: 7.2px;
    font-size: 12.8px;
  }
}

.MoreInfoText {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-left: 22.4px;
  margin-top: 9.6px;
  margin-bottom: 32px;

  @media (max-width: 768px) {
    margin-left: 12.8px;
    margin-top: 7.2px;
    font-size: 8px;
    line-height: 11.2px;
    margin-bottom: 27.2px;
  }
}