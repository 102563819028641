.TreatmentLevel {
  padding: 32px;

  @media (max-width: 1024px) {
    max-width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.TreatmentLevelCardsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
}

.TreatmentLevelHeading {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: var(--gray-2);
  margin-bottom: 32.8px;

  @media (max-width: 1024px) {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 44px;
    display: none;
  }
}