.FormScreenContainer {
  display: flex;
  flex-direction: column;
  width: 20%;

  @media (max-width: 1440px) {
    width: 30%;
  }

  @media (max-width: 1024px) {
    width: 40%;
  }

  @media (max-width: 768px) {
    width: 50%;
  }

  @media (max-width: 600px) {
    width: 70%;
  }

  @media (max-width: 375px) {
    width: 100%;
  }
}
.custom-hover {
  background: linear-gradient(to right, #ab8aff, #3b00d1);
  background-position: 0 0;
}
.custom-hover:hover {
  background-position: 0 -10%;
}

.QuestionProceedButton {
  position: absolute;
  bottom: calc(var(--footer) + 25px);
  right: 25px;

  @media (max-width: 768px) {
    bottom: calc(var(--footer) + 20px);
    right: 20px;
  }
}
