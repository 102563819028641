.TextFieldContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  padding-top: 2.5px;
  @media (max-width: 768px) {
    padding-top: 4px;
  }
}

.TextFieldLabel {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 450;
  font-size: 19.2px;
  line-height: 25.6px;
  text-align: center;
  letter-spacing: -0.333333px;
  color: var(--gray-2);
  margin-bottom: 12.8px;

  @media (max-width: 768px) {
    font-weight: 600;
    font-size: 12.8px;
    line-height: 18px;
    margin-bottom: 10.4px;
  }
}

.TextFieldInput {
  box-sizing: border-box;
  background: var(--white);
  border: 1px solid rgba(46, 46, 47, 0.5);
  border-radius: 5px;
  width: 100%;
  max-width: 350px;
  height: 36px;
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 19.2px;
  line-height: 25.6px;
  letter-spacing: -0.333333px;
  padding: 10px;
  color: var(--black);

  @media (max-width: 768px) {
    font-weight: 700;
    font-size: 12.8px;
    line-height: 18px;
    height: 40px;
  }
}

.TextFieldErrorText {
  color: red;
  font-family: 'Satoshi';
  font-style: normal;
}