.chat-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
}

.chat-box {
  width: 80%;
  height: 90vh;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chat-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 20px;
  background-color: #3b00d1;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  position: relative;
  flex-shrink: 0;
}

#sub-heading {
  font-size: 12px;
  color: #e0e0e0;
  margin-top: 4px;
}

.msg_card_body {
  flex: 1;
  overflow-y: auto;
  padding: 15px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column-reverse;
}

.d-flex {
  display: flex;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.img_cont_msg {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.user_img_msg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.msg_cotainer {
  max-width: 70%;
  background-color: #e1f5fe;
  padding: 10px 15px;
  border-radius: 20px;
  font-size: 14px;
  color: #333;
}

.msg_cotainer_send {
  background-color: #e1eceb;
}

.msg_time {
  font-size: 10px;
  color: #666;
  margin-top: 5px;
}

.msg_time_send {
  text-align: right;
}

.card-footer {
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #f8f9fa;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.type_msg {
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 12px 15px;
  outline: none;
  flex: 1;
  font-size: 14px;
  color: #495057;
  background-color: #fff;
}

.type_msg:focus {
  border-color: #3b00d1;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
}

.send_btn {
  border: none;
  background-color: #3b00d1;
  color: #fff;
  font-size: 18px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.send_btn:hover {
  background-color: #0056b3;
}

.send_btn i {
  margin: 0;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .chat-box {
    width: 100%;
    height: 100vh;
    border-radius: 0px;
  }

  .chat-header {
    font-size: 14px;
    padding: 10px 15px;
    border-radius: 0%;
  }

  .msg_card_body {
    padding: 10px;
  }

  .msg_cotainer {
    max-width: 80%;
    padding: 8px 12px;
    font-size: 13px;
  }

  .msg_time {
    font-size: 9px;
  }

  .card-footer {
    padding: 8px;
  }

  .type_msg {
    font-size: 13px;
    padding: 10px 12px;
  }

  .send_btn {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .chat-box {
    width: 100%;
    height: 100vh;
  }

  .chat-header {
    font-size: 14px;
    padding: 8px 12px;
  }

  .msg_card_body {
    padding: 8px;
  }

  .msg_cotainer {
    max-width: 85%;
    padding: 6px 10px;
    font-size: 12px;
  }

  .msg_time {
    font-size: 8px;
  }

  .card-footer {
    padding: 6px;
  }

  .type_msg {
    font-size: 12px;
    padding: 8px 10px;
  }

  .send_btn {
    width: 38px;
    height: 38px;
    font-size: 14px;
  }
}
