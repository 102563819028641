.ResultsTopTitleBar {
  height: 113px;
  display: flex;
  justify-content: center;
  align-items: center;
    width: 100%;

  @media (max-width: 1024px) {
    height: 80px;
  }
}

.ResultsDescriptionContainer {
  flex: 1 1 0%;
  overflow: auto;
  display: flex;
  align-items: center;
}

.ResultsContent {
  flex: 1;
  background-image: url("../../assets/images/results-diagnosis-background.png");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  background-position: center;
  flex-direction: row;
  display: flex;
  width: 100%;
  padding-top: 20px;
  height: calc(100% - var(--footer) - var(--header) - 113px);
  @media (max-width: 1024px) {
    background-attachment: fixed;
    height: calc(100% - var(--footer) - var(--header) - 80px);
    display: unset;
    overflow: auto;
    padding-top: 27px;
  }
}

.ResultsCardContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-height: 100%;

  @media (max-width: 1024px) {
    align-items: center;
    padding-bottom: 20px;
  }
}

.ResultsDivider {
  width: 1px;
  background-color: white;
  height: 60%;
  align-self: center;

  @media (max-width: 1024px) {
    display: none;
  }
}

.ResultsDescriptionContent {
  flex: 1;
  max-height: calc(100vh - var(--footer) - var(--header) - 113px);
  margin-top: 10px;
  @media (max-width: 1024px) {
    display: none;
  }
}

.ResultsDescriptionContentShow {
  @media (max-width: 1024px) {
    background-color: rgba(0, 0, 0, 0.4);
    display: unset;
    position: absolute;
    max-height: calc(100vh - var(--footer));
    margin: unset;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}