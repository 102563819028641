.TreatmentVideoPlayer {
  background-color: white;
  width: 100%;
  bottom: calc(0px + var(--footer));
  position: absolute;
  z-index: 1;
  left: 0px;
  height: calc(100% - var(--footer));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.TreatmentVideo {
  width: 75%;
  max-height: calc(100vh - var(--footer) - 128px - 76px - 64px - 76px);
  aspect-ratio: 16 / 9;
  flex: 1;
  padding: 0px 16px;

  @media (max-width: 1024px) {
    width: 100%;
  }
}

.TreatmentVideoInfo {
  // flex: 1;
}

.TreatmentVideoLabel {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.439164px;
  color: var(--gray-2);
  margin-bottom: 8px;

  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 2px;
  }
}

.TreatmentVideoText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.439164px;
  color: var(--gray-2);
  margin-bottom: 3px;

  @media (max-width: 768px) {
    font-size: 11px;
    line-height: 14px;
    margin-bottom: 1px;
  }
}

.TreatmentVideoContainer {
  flex: 1;
  display: flex;
  align-items: center;
}

.TreatmentVideoDurationBarContainer {
  // height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
  margin-top: 53px;

  @media (max-width: 768px) {
    height: 32px;
    margin-bottom: 9.2px;
    margin-top: 40px;
  }
}

.TreatmentVideoDurationBar {
  background: #ededed;
  border: 0.5px solid #939393;
  border-radius: 6px;
  width: 300px;

  @media (max-width: 768px) {
    width: 240px;
    border-radius: 4px;
  }
}

.TreatmentVideoDurationBarProgress {
  background: #40c302;
  border-radius: 6px;
  min-width: 3%;
  height: 15px;
  margin: 2.5px;

  @media (max-width: 768px) {
    height: 9px;
    margin: 1px;
    border-radius: 4px;
  }
}

.MobileVideoControls {
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}

.DesktopVideoControls {
  @media (max-width: 768px) {
    display: none;
  }
}

.TreatmentVideoControls {
  height: 128px;
  width: 100%;
  background-color: var(--light-cyan-2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: unset;

  @media (max-width: 768px) {
    // align-items: flex-start;
    height: 68px;
  }
}

.TreatmentButtons {
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
}

.TreatmentButtonContainer {
  margin: 0px 12px;
  cursor: pointer;

  @media (max-width: 768px) {
    margin: 10px 7.5px;
  }
}

.TreatmentButtonLoading {
  .TreatmentButtonContainer {
    opacity: 0.5;
  }
}

.TreatmentVideoLoader {
  position: absolute;
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.25);
  z-index: 2;
  width: 100%;
}

.PauseButton {
  content: url("../../assets/images/pause-button.png");
  width: 64px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));

  @media (max-width: 768px) {
    width: 32px;
  }
}

.PlayButton {
  content: url("../../assets/images/play-button.png");
  width: 64px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));

  @media (max-width: 768px) {
    width: 48px;
  }
}

.PlayFromStartButton {
  content: url("../../assets/images/play-from-start-button.png");
  width: 43px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));

  @media (max-width: 768px) {
    width: 32px;
  }
}

.TreatmentVideoReturnButton {
  position: absolute;
  left: 0px;
  margin-left: 32px;

  @media (max-width: 768px) {
    // bottom: 0px;
    // margin-bottom: 16px;
    margin-left: 16px;
  }
}
