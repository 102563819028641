@import '../../App.scss';

.Height {
  height: calc(100% - var(--footer));
}

.HomeButton {
  margin-top: scale(22px);

  @media (min-width:1025px) and (max-width:1280px) {
    margin-top: scale(8px);
  }

  @media (max-width: 768px) {
    margin-top: scale(23px);
  }
}

.Home-Background {
  @extend .Height;
  background: url("../../assets/images/home-background.png");
  background-color: var(--home-cyan);
  background-size: cover;
  position: absolute;
  width: 100%;
  display: flex;
  z-index: -1;
  justify-content: center;
  align-items: center;
}

.Home-Screen-Top-Overlay {
  position: absolute;
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 32%;
  background-color: rgba(251, 241, 241, 0.6);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  @media (max-width: 425px) {
    height: 25%;
  }
  @media (min-width:1025px) and (max-width:1280px) {
    height: 29% !important; 
  }
}

.Home-Screen-Remaining-Overlay {
  position: absolute;
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 32%;
  height: 68%;

  @media (min-width:1025px) and (max-width:1280px){
    height: 70% !important;
    top:29% !important;
  }

  @media (max-width: 425px) {
    top: 25%;
    height: 75%;
  }
}

.Home-Circle-Top-Overlay {
  position: absolute;
  top: 24%;
  right: 10%;
  height: 8%;
  content: url("../../assets/images/circle-1440.png");

  @media (min-width:1025px) and (max-width:1280px) {
    top: 22.3% !important;
    right: 8% !important;
    height: 7% !important;
  }

  @media (max-width: 1024px) {
    top: 25%;
    right: 8%;
    height: 7%;
  }

  @media (max-width: 768px) {
    content: url("../../assets/images/circle-768.png");
    top: 26%;
    height: 6%;
  }

  @media (max-width: 600px) {
    height: 6%;
  }

  @media (max-width: 425px) {
    top: 21%;
    height: 4%;
  }
}

.Home-Screen-Bottom-Overlay {
  @extend .Height;
  position: absolute;
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Middle-Blur {
  width: scale(500px);
  height: scale(500px);
  background: rgba(255, 246, 246, 0.7);
  filter: blur(136.139px);

  @media (max-width: 768px) {
    width: scale(390px);
    height: scale(390px);
  }
}

.HomeContainer {
  @extend .Height;
  position: absolute;
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.HomeTitle {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  @media (max-width: 425px) {
    margin-bottom: 40px;
  }
}

.HomeControls {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;

  @media (max-width: 425px) {
    margin-top: 40px;
  }
}

.HomeTitleDescription {
  max-width: scale(375px);
  text-align: center;
  margin-top: 10px;

  @media (max-width: 768px) {
    max-width: scale(250px);
    margin-top: 15px;
  }
}

.HomeQuestion {
  max-width: scale(425px);
  text-align: center;

  @media (max-width: 768px) {
    max-width: scale(262.5px);
  }
}

.Home10 {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: calc(scale(131.5px) + var(--footer));

  @media (max-width: 768px) {
    bottom: calc(scale(92.5px) + var(--footer));
  }
  @media (min-width: 769px) and (max-width:1024px) {
    bottom: calc(scale(65.5px) + var(--footer)) !important;
  }
  @media (min-width: 1025px) and (max-width:1280px) {
    bottom: calc(scale(95.5px) + var(--footer)) !important;
  }
}

.Home10background {
  position: fixed;
  content: url("../../assets/images/ellipse-80.png");
  filter: drop-shadow(0px 4px 3px var(--gray-4));
  width: scale(117.5px);
  ;
  opacity: 0.75;

  @media (min-width:1025px) and (max-width:1280px) {
    width: scale(100.5px);
  }

  @media (max-width: 1024px) {
    width: scale(92.5px);
    ;
  }
}

.Home10Text {
  font-family: 'Nokora';
  font-weight: 700;
  font-size: scale(58px);
  ;
  z-index: 0;

  @media (max-width: 768px) {
    font-size: scale(37.8px);
  }
}