.Height {
    height: 100vh;
  }
  
.Diagnostics-Container {
  height: calc(100vh - 160px);
  overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  
.Diagnostics-Right-Section {
    flex: 1;
    width: 100%;
  }


.Diagnostics-loading{
  height: 70vh;
  display: grid;
  place-self: center;
  align-content: center;

} 


.Diagnostics-Header {
    height: 80px;
    background: linear-gradient(90.01deg, var(--light-blue) 49.45%, rgba(234, 234, 234, 0) 91.29%);
    padding-left: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  
    @media (max-width: 768px) {
      height: 62px;
      padding-left: 16px;
    }
  }

  
.Diagnostics-Right-Section-NavBar-Border {
    border: 1px solid var(--gray-3);
    margin: 0px 32px;
  
    @media (max-width: 768px) {
      border: 0.5px solid var(--gray-3);
      margin: 0px 16px;
    }
  }
  
.Diagnostics-Content {
    overflow: auto;
    height: calc(100vh - 240px);
    padding: 32px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    gap: 24px;

    @media (max-width: 840px) {
      padding: 16px;
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 560px) {
      padding: 16px;
      place-items: center;
      grid-template-columns: 1fr;
    }
  }
  
.Diagnosis-Card {
    background: var(--white);
    border-radius: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 24px;
    margin-bottom: 24px;
    height: min(100%, 750px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    h2 {
      font-size: 20px;
      color: darkslategrey;
      text-overflow: ellipsis;
      font-weight: bold;
    }
  
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-radius: 8px;
      margin: 16px 0;
    }
  
    p {
      font-size: 16px;
      color: slategray;
      margin-bottom: 16px;
    }
  
    .Diagnosis-Details {
      display: flex;
      flex-direction: column;
      gap: 8px;
  
      span {
        font-size: 14px;
        color: var(--gray-3);
      }
    }
    .Diagnosis-Card-Button {
      font-size: 0.85rem;
      padding-inline: 1.2rem;
      padding-block: 0.8rem;
      background-color: var(--blue);
      border-radius: 0.8em;
      color: var(--white);
      font-weight: bold;
      margin-top: 1rem;
      cursor: pointer;
      transition: all 0.3s ease-out;

      &:hover{
        background-color: var(--navy-blue);
      }

    }

    @media (max-width: 768px) {
      max-width: 400px;
    }
}
  