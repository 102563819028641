.BodyContainer {
  width: 100%;
  overflow: auto;
}

.BodyImage {
  max-height: 475px;
  aspect-ratio: 0.658;
  background-color: white;
  object-fit: contain;
  padding: 20px 0px 10px 0px;

  @media (max-width: 768px) {
    max-height: 340px;
    padding: 15px 0px 8px 0px;
  }
}

.ButtonOnImage {
  position: absolute;
  justify-content: center;
  transform: translateY(-50%);
  padding: 6.4px 11.2px;
  background-color: white;
  color: rgb(116, 116, 116);
  font-family: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  font-weight: 300;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid rgba(59, 0, 209, 0.5);
  text-wrap: nowrap;
  margin: 0px 0px;
  margin-bottom: -36.8px;
  margin-right: 10px;
  transition: border 0.3s ease, box-shadow 0.3s ease;

  @media (max-width: 768px) {
    padding: 4px 8px;
    font-size: 12px;
    line-height: 16px;
    margin: 0px 0px;
    margin-bottom: -26px;
    margin-right: 8px;
    border-radius: 5px;
  }
}

.ButtonOnImage:hover {
  border: 1px solid var(--blue);
  box-shadow: 0px 0px 10px 2px rgba(59, 0, 209, 0.5);
}

.BodyImage-male-front {
  content: url("../../assets/images/body-male-front.png");
}

.BodyImage-male-back {
  content: url("../../assets/images/body-male-back.png");
}

.BodyImage-female-front {
  content: url("../../assets/images/body-female-front.png");
}

.BodyImage-female-back {
  content: url("../../assets/images/body-female-back.png");
}

.ImageContent {
  position: relative;
  text-align: center;
  margin-top: 12px;
}

.BodySelected {
  background-color: var(--blue);
  transition: background-color 0.5s ease;
}

.BodyButtonText {
  color: white;
  transition: color 0.5s ease;
}

.BodyRotateButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 24px;
}

.BodyRotateButton {
  height: 44px;
  width: 51px;
  background-image: url("../../assets/images/rotate-body.png");
  background-size: cover;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.BodyRotateButtonText {
  margin-right: 10px;
  margin-left: 5px;
  font-family: 'Nokora';
  font-style: normal;
  font-weight: 600;
  font-size: 11.2px;
  line-height: 15.2px;
  letter-spacing: -0.333333px;
}