.Footer {
  background-color: var(--gray-3);
  height: var(--footer);
  padding-left: 45px;
  display: flex;
  justify-content: left;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: center;
    padding: 16px;
  }
}