.TreatmentLoading-Screen-Content {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
}

.TreatmentLoading-Description {
  font-weight: 400;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 19.2px;
  max-width: 350px;
  line-height: 26px;
  @media (max-width: 768px) {
    padding-top: 15px;
    font-size: 16px;
    max-width: 300px;
  }
}

.TreatmentLoading-Percentage {
  font-weight: 600;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 28.8px;
  max-width: 350px;
  line-height: 39px;
  @media (max-width: 768px) {
    padding-top: 15px;
    font-size: 17.6px;
    max-width: 300px;
  }
}

.Treatment-Loader {
  content: url("../../assets/images/treatment-loading-animation.gif");
  height: 190px;
  text-align: center;
  margin-left: 80px;
  margin-top: 24px;
  @media (max-width: 768px) {
    height: 130px;
    margin-left: 85px;
    margin-top: 0px;
  }
}