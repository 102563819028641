.Header {
  height: var(--header);
  padding-left: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--light-gray-4);

  @media (max-width: 768px) {
    padding-left: 20px;
  }
}

.HeaderLogo {
  width: 16px;

  @media (max-width: 768px) {
    width: 13px;
  }
}

.HeaderRightArea {
  padding-right: 50px;

  @media (max-width: 768px) {
    padding-right: 20px;
  }
}

.LogoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.LogoFontWeight {
  font-weight: 600;

  @media (max-width: 768px) {
    margin-left: 9px;
  }

}

.Text1 {
  @extend .LogoFontWeight;
  margin-left: 13px;

  @media (max-width: 768px) {
    margin-left: 9px;
  }
}

.Text2 {
  @extend .LogoFontWeight;
  margin-left: 5px;

  @media (max-width: 768px) {
    display: none;
  }
}