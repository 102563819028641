.ProgressBarsContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.ProgressBar {
  background-color: var(--light-gray);
  transition: background-color 0.5s ease;
  height: 7.2px;
  width: 26.4px;
  margin: 1.5px;
  
  @media (max-width: 768px) {
    width: 15.2px;
  }
}

.ProgressBarStarting {
  width: 24px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  @media (max-width: 768px) {
    width: 14px;
  }
}

.ProgressBarEnding {
  width: 24px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  @media (max-width: 768px) {
    width: 14px;
  }
}

.ProgressDone {
  background-color: var(--blue);
  transition: background-color 0.5s ease;
}