@font-face {
  font-family: 'Nokora';
  font-weight: 700 800;
  src: url('./Nokora-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nokora';
  font-weight: 500 600;
  src: url('./Nokora-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nokora';
  font-weight: 300 400;
  src: url('./Nokora-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Nokora';
  font-weight: 100 200;
  src: url('./Nokora-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Satoshi';
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  src: url('./Satoshi-Variable.ttf') format('truetype');
}

@font-face {
  font-family: 'Satoshi';
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  src: url('./Satoshi-VariableItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  src: url('./Montserrat-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  src: url('./Montserrat-Italic-VariableFont_wght.ttf') format('truetype');
}