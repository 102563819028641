@import '../../assets/fonts/Fonts.scss';
@import '../../App.scss';

.Font {
  font-style: normal;
  line-height: 110%;
}

.Font-Montserrat {
  @extend .Font;
  font-family: 'Montserrat';
}

.Satoshi {
  font-family: 'Satoshi';
}

.Font-Satoshi {
  @extend .Font;
  @extend .Satoshi;
}

.Heading1 {
  @extend .Font-Montserrat;
  font-weight: 670;
  font-size: 76px;
  line-height: scale(71.5px);
  letter-spacing: 0.02em;

  @media (max-width: 768px) {
    font-size: 44px;
    line-height: scale(41.5px);
  }
}

.Heading3 {
  @extend .Font-Satoshi;
  font-weight: 450;
  font-size: 18px;
  line-height: scale(43.2px);

  @media (min-width:320px) and (max-width:426px){
    font-size: 16px !important;
  }

  @media (min-width:1025px) and (max-width:1280px){
    font-size: 16px !important;
  }

  @media (max-width: 1024px) {
    padding-top: 0px;
   }

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: scale(29.7px);
  }

}

.Heading4 {
  @extend .Font-Satoshi;
  font-weight: 100;
  font-style: regular;
  font-size: 18px;
  line-height: scale(32.4px);
  align-items: start;
  vertical-align: top;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: scale(24.3px);
  }
}

.ListItem {
  line-height: scale(38px);

  @media (max-width: 768px) {
    line-height: scale(28px);
  }
}

.Paragraph1 {
  @extend .Heading4;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: -0.33%;
}

.Paragraph2 {
  @extend .Heading4;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.33%;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 23px;
  }
}

.Center {
  text-align: center;
  margin: 0px 10px;
}

.PaddingTop24 {
  padding-top: 24px;
}

.PaddingTop40 {
  padding-top: 40px;
}

.PaddingTop50 {
  padding-top: 50px;
}

.PaddingBottom50 {
  padding-bottom: 50px;
}

.PaddingBottom {
  padding-bottom: 45px;

  @media (max-width: 768px) {
    padding-bottom: 25px;
  }
}