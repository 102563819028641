.Height {
  height: calc(100% - var(--footer));
}

.ScreenBackground {
  @extend .Height;
  display: flex;
  flex-direction: column;
}

.ContainerHeight {
  flex: 1;
}

.Container {
  @extend .ContainerHeight;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  flex-direction: column;
}

.BackButton {
  position: absolute;
  top: 120px;
  z-index: 1;
}